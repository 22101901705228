$primary: #fa7f08;
$secondary: #22babb;
$font-family-sans-serif: hedley-new-web, -apple-system, system-ui,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
@import "../node_modules/bootstrap/scss/bootstrap";

:root {
  --color-one: #348888;
  --color-two: #22babb;
  --color-three: #9ef8ee;
  --color-four: #fa7f08;
  --color-five: #f24405;
  --brand-font: "Dela Gothic One", hedley-new-web, -apple-system, system-ui,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

body {
  // background: rgb(255, 255, 255);
  // background: linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 1) 33%,
  //   rgba(158, 248, 238, 1) 66%,
  //   rgba(250, 127, 8, 1) 100%
  // );
}

footer {
  background: var(--color-one);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem;
}

.btn-cam {
  color: white;
}

.btn-white-text {
  color: white;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.emoji {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-size: 1.25rem;
}

.fizzgen-card-title {
  font-family: var(--brand-font);
  color: black !important;
}

.footer-icons {
  color: white;
  font-size: 1.25em;
}

.img-captured {
  height: 360px;
  width: auto;
  text-align: center;
}

.modal {
  overflow-y: auto;
}

.modal-dialog {
  overflow-y: initial !important;
}

.nav-main {
  background-color: var(--color-four);
}

.offcanvas {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 15%,
    rgba(158, 248, 238, 1) 66%,
    rgba(250, 127, 8, 1) 100%
  );
}

.text-brand {
  font-family: var(--brand-font);
}

.text-brand-white {
  font-family: var(--brand-font);
  color: white;
}

.transfer-btn {
  color: white;
}

#account-toggle-btn {
  color: white;
}

#card-body-top {
  background-color: var(--color-three);
}

#content-wrap {
  padding-bottom: 4.5rem;
}

#filter-dropdown {
  color: white;
}

#filter-select {
  background-color: var(--color-two);
  color: white;
}

#fizzgen-me-row {
  font-family: var(--brand-font);
}

#gallery-main-div {
  width: 99%;
}

#nav-account-icon {
  font-size: 2em;
}

#nav-display-name {
  text-align: center;
  font-size: 5.5vw;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#result-text {
  font-size: 1.5em;
}

#text-artist {
  color: white;
}

#to-be-replaced {
  height: 360px;
  width: 360px;
}

// ---------------------------------------------------------------------------------------

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-three);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 3px;
  bottom: 4px;
  background-color: var(--color-four);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--color-two);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--color-two);
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
